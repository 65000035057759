import { ReactElement, useEffect, useState } from 'react';
import { useToast, useUser } from '@hooks';
import Modal from '@components/Modal';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import UserImage from '@components/UserImage';
import Buttons from '@components/Buttons';
import PlusCircle from '@components/Icons/PlusCircle';
import s from './ManageTeamModal.module.scss';

type Props = {
  organizationId: string;
  onClose: () => void;
};

const ManageTeamModal = ({ organizationId, onClose }: Props): ReactElement => {
  const [user] = useUser();
  const [canInvite] = useState(false);

  const {
    data: organizationMembers,
    fetchingState: fetchingMembers,
    getData: getOrganizationMembers,
  } = useNoodleApi(tsClient.organizations.getOrganizationMembers);

  const { fetchingState: addingMember, getData: createMemberFn } = useNoodleApi(tsClient.organizations.createOrganizationMember, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.INFO, 'Organization member added'],
  });

  useEffect(() => {
    getOrganizationMembers({ organizationId });
  }, [getOrganizationMembers, organizationId]);

  return (
    <Modal hideBottomInset title={'Manage Team'} onClose={onClose} >
      <div className={s.container}>
        {(fetchingMembers.isFetching || addingMember.isFetching) && <ProgressIndicator />}
        {!(fetchingMembers.isFetching || addingMember.isFetching) && organizationMembers && (
          <ul className={s.members}>
            {organizationMembers.items.map(member => (
              <li key={member.id}>
                <UserImage
                  color={member.person?.primaryColour?.hex}
                  url={member.person?.image?.url}
                  size={40}
                  key={member.id}
                  name={member.person?.name}
                />
                <div className={s.memberInfo}>
                  <div>
                    <strong className="body-sm-bold">{member.person?.name}</strong>
                    {user?.id === member.person?.id && <small>You</small>}
                  </div>
                  {member.person?.email && <p>{member.person?.email}</p>}
                </div>
              </li>
            ))}
          </ul>
        )}
        {canInvite && (
          <form
            onSubmit={async e => {
              e.preventDefault();
              const form = e.target as HTMLFormElement;
              await createMemberFn({
                email: form.email.value,
                name: form.username.value,
                organizationId,
                permissions: {
                  canAssign: form.canAssign.checked,
                  canInitiateWorkflows: form.canInitiateWorkflows.checked,
                  canInviteCollaborators: form.canInviteCollaborators.checked,
                  canMakePayments: form.canMakePayments.checked,
                  canReceiveCustomerNotifications: form.canReceiveCustomerNotifications.checked,
                  inviteToAllCases: form.inviteToAllCases.checked,
                },
              });
            }}
            className={s.form}
          >
            <details>
              <summary>
                <PlusCircle weight="fill" size={16} /> Invite a member
              </summary>
              <div>
                <label>
                  Name
                  <input type="text" name="username" placeholder="Name" required autoComplete="name" />
                </label>
                <label>
                  Email
                  <input type="email" name="email" placeholder="Email" required autoComplete="email" />
                </label>
                <label>
                  <input type="checkbox" name="canInviteCollaborators" />
                  Can invite collaborators
                </label>
                <label>
                  <input type="checkbox" name="canAssign" />
                  Can assign
                </label>
                <label>
                  <input type="checkbox" name="canInitiateWorkflows" />
                  Can initiate workflows
                </label>
                <label>
                  <input type="checkbox" name="canMakePayments" />
                  Can make payments
                </label>
                <label>
                  <input type="checkbox" name="canReceiveCustomerNotifications" />
                  Can receive customer notifications
                </label>
                <label>
                  <input type="checkbox" name="inviteToAllCases" />
                  Invite to all cases
                </label>
                <Buttons isSecondary type="submit">
                  Invite
                </Buttons>
              </div>
            </details>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ManageTeamModal;
