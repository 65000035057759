import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import CustomSelect from '@components/FormFields/CustomSelect';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { Person } from '@typings/api-models';
import { useUser } from '@hooks';
import getTokenForUser from '@tsClient/auth/getTokenForUser';
import ProgressIndicator from '@components/ProgressIndicator';
import Users from '@components/Icons/Users';
import Tooltip from '@components/Tooltip';
import AddNewUserModal from './AddNewUserModal';
import s from './AssociatedUserSelect.module.scss';

const createNewOption = {
  key: 'create-new-person',
  title: 'Create New User',
  value: 'create-new-person',
};

type Props = {
  canCreateNewUser?: boolean;
  redirectTo?: string;
  creatorSlug?: string;
  isIcon?: boolean;
};

const AssociatedUserSelect: React.FC<Props> = ({ canCreateNewUser = false, redirectTo, creatorSlug, isIcon }) => {
  const [associatedUsers, setAssociatedUsers] = useState<Pick<Person, 'id' | 'name'>[]>([]);
  const [isCreatingNewUser, setIsCreatingNewUser] = useState(false);
  const [isChangingUser, setIsChangingUser] = useState(false);
  const { getData: getAssociatedUsersFn } = useNoodleApi(tsClient.associatedUsers.getAssociatedUsers, {
    toastOnError: true,
  });
  const { getData: createAssociatedUser } = useNoodleApi(tsClient.associatedUsers.createAssociatedUser, {
    toastOnError: true,
  });
  const { getData: getTokenFn } = useNoodleApi(getTokenForUser, { toastOnError: true });
  const [user, _, setUserByToken] = useUser();
  const router = useRouter();
  const enabled = associatedUsers && associatedUsers.length >= 2; // only show to folks with associated users, others can't even create a new one.

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (user?.id) {
        const { data } = await getAssociatedUsersFn({});
        setAssociatedUsers(data?.items || []);
      }
    };
    getData();
  }, [getAssociatedUsersFn, user]);

  const changeUser = async (userId: string): Promise<void> => {
    setIsChangingUser(true);
    const { data } = await getTokenFn({ userId });
    if (data?.token) {
      setUserByToken(data.token);
      if (redirectTo) {
        router.push(redirectTo);
      } else {
        window.location.reload();
      }
    } else {
      setIsChangingUser(false);
    }
  };

  const handleCreateNewUser = async ({ email, name }: { email: string; name: string }): Promise<void> => {
    const createResponse = await createAssociatedUser({
      creatorSlug,
      email,
      name,
    });

    if (createResponse.data) {
      await changeUser(createResponse.data.id);
    }
  };

  const handleUserSelect = async (selectedValue: string): Promise<void> => {
    if (selectedValue === createNewOption.value) {
      setIsCreatingNewUser(true);
    } else {
      changeUser(selectedValue);
    }
  };

  const options = useMemo(() => {
    const associatedUserOptions = associatedUsers.map(associatedUser => ({
      key: associatedUser.id,
      title: associatedUser.name || '',
      value: associatedUser.id,
    }));
    if (!canCreateNewUser) {
      return associatedUserOptions;
    }
    return [...associatedUserOptions, createNewOption];
  }, [associatedUsers, canCreateNewUser]);

  if (!enabled || !user) {
    return null;
  }

  if (isChangingUser) {
    return (
      <div className={s.associatedUserSelect}>
        <ProgressIndicator />
      </div>
    );
  }

  if (isIcon) {
    return (
      <Tooltip text="Switch User" className={s.tooltipWidth}>
        <div className={s.associatedUserSelectIcon}>
          <Users size={24} />
          <select
            id="teams"
            onChange={e => {
              handleUserSelect(e.target.value);
            }}
            defaultValue={user?.id}
          >
            {options.map(option => (
              <option key={option.key} value={option.value}>
                {option.title || ''}
              </option>
            ))}
          </select>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={s.associatedUserSelect}>
      {isCreatingNewUser && <AddNewUserModal onClose={() => setIsCreatingNewUser(false)} onSubmit={handleCreateNewUser} />}
      <CustomSelect id={'associatedUsers'} onChange={handleUserSelect} options={options} value={user?.id || ''} />
    </div>
  );
};

export default AssociatedUserSelect;
