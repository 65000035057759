import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import { useParams } from 'next/navigation';
import * as tsClient from '@tsClient';
import * as format from '@format';

import type { HookResponse, ListViewRegularItem } from './common';
import { PAGE_SIZE } from './common';

type ThisUserWorkflow = Awaited<ReturnType<typeof tsClient.creators.getUserWorkflows>>['items'][number];
type Status = 'completed' | 'archived' | 'paused' | 'data-collection' | 'filed-and-pending' | 'preparing-case';
type Item = HookResponse['items'][number];

const formatUserWorkflowContext = (userWorkflowContext: Pick<ApiModels.UserWorkflowContext, 'label' | 'value' | 'type'>[]): string => {
  let returnString = '';
  userWorkflowContext.forEach((context, index) => {
    returnString = returnString.concat(`${context.label}: ${context.value}${index === userWorkflowContext.length - 1 ? '': ', '}`);
  });
  return returnString;
};

interface StatusProperty {
  label: string;
  color: string;
}
const StatusToBadge: Record<Status, StatusProperty> = {
  'archived': { color: 'var(--color-workflow-archived)', label: 'Archived' },
  'completed': { color: 'var(--color-workflow-completed)', label: 'Completed' },
  'data-collection': { color: 'var(--color-workflow-data-collection)', label: 'Data Collection' },
  'filed-and-pending': { color: 'var(--color-workflow-filed-and-pending)', label: 'Filed and Pending' },
  'paused': { color: 'var( --color-workflow-paused)', label: 'Paused' },
  'preparing-case': { color: 'var(--color-workflow-preparing-case)', label: 'Processing' },
};

const statusToBadge = (status: string): ListViewRegularItem['badge'] | undefined => {
  if (status in StatusToBadge) {
    return StatusToBadge[status as keyof typeof StatusToBadge];
  }
  return undefined;
};

const userWorkflowToItem = ({
  hasAssignedTasksByUserWorkflow,
  selectedStatus,
  userWorkflow,
  creatorSlug,
}: {
  hasAssignedTasksByUserWorkflow: Record<string, boolean | 'fetching'>;
  selectedStatus: Status;
  userWorkflow: Awaited<ReturnType<typeof tsClient.creators.getUserWorkflows>>['items'][number];
  creatorSlug: string;
}): Item => {
  const hasAssignedTasks = hasAssignedTasksByUserWorkflow[userWorkflow.id] !== 'fetching' && hasAssignedTasksByUserWorkflow[userWorkflow.id];

  return {
    badge: statusToBadge(userWorkflow.status),
    bottomLine: userWorkflow.workflow.name,
    href: getUrl(IDENTIFIERS.USER_PROFILE_ACTIVITY_DETAILS, { creatorSlug, userWorkflowId: userWorkflow.id }),
    id: userWorkflow.id,
    isMuted: selectedStatus === 'archived',
    label: userWorkflow.title,
    linkedCells: {
      items: userWorkflow.associatedUserWorkflows.map((uw) => userWorkflowToItem({
        creatorSlug,
        hasAssignedTasksByUserWorkflow,
        selectedStatus,
        userWorkflow: { ...uw, associatedUserWorkflows: [] },
      })),
      type: 'workflows',
    },
    messageCount: userWorkflow.messageCount,
    needsAttention: selectedStatus === 'data-collection'
      ? Boolean(hasAssignedTasks && hasAssignedTasks !== 'fetching')
      : undefined,
    progress: selectedStatus === 'data-collection'
      ? format.percentageComplete({
        completed: userWorkflow.numCompletedTasks,
        total: userWorkflow.numTotalTasks,
      })
      : undefined,
    topLine: formatUserWorkflowContext(userWorkflow.context),
  };
};

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id, creatorSlug }) => getUrl(IDENTIFIERS.USER_PROFILE_ACTIVITY_DETAILS, { creatorSlug: creatorSlug as string, userWorkflowId: id });

const useActivity = ({
  creatorId,
  creatorSlug,
  personId,
}: {
  creatorId: string | undefined;
  creatorSlug: string;
  personId?: string;
}): HookResponse => {
  const pathParams = useParams<{ userWorkflowId?: string }>();
  const [numCompletedUserWorkflows, setNumCompletedUserWorkflows] = useState(0);
  const [numArchivedUserWorkflows, setNumArchivedUserWorkflows] = useState(0);
  const [numPausedUserWorkflows, setNumPausedUserWorkflows] = useState(0);
  const [numDataCollectionUserWorkflows, setNumDataCollectionUserWorkflows] = useState(0);
  const [numFiledAndPendingUserWorkflows, setNumFiledAndPendingUserWorkflows] = useState(0);
  const [numPreparingCaseUserWorkflows, setNumPreparingCaseUserWorkflows] = useState(0);
  const [userWorkflows, setUserWorkflows] = useState<ThisUserWorkflow[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<Status[]>([]);
  const [hasAssignedTasksByUserWorkflow, setHasAssignedTasksByUserWorkflow] = useState<Record<string, boolean | 'fetching'>>({});
  const [numPages, setNumPages] = useState(0);
  const mostRecentRequestRef = useRef<string | null>(null);
  const { getData: getUserWorkflowsFn } = useNoodleApi(tsClient.creators.getUserWorkflows);
  const { getData: getUserWorkflowProgressFn } = useNoodleApi(tsClient.workflows.getUserWorkflowProgress);

  const selectedId = pathParams?.userWorkflowId;

  const fetchWorkflows = useCallback(async ({
    page: newPage,
    search: newSearch,
    statuses,
  }: {
      page: number;
      search: string;
      statuses: Status[]
    }): Promise<void> => {

    if (creatorId) {
      setCurrentPage(newPage);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;
      setIsFetching(true);
      setUserWorkflows([]);
      setCurrentPage(newPage);

      const response = await getUserWorkflowsFn({
        archived: statuses.includes('archived') || statuses.length === 0,
        completed: statuses.includes('completed') || statuses.length === 0,
        creatorId,
        dataCollection: statuses.includes('data-collection') || statuses.length === 0,
        filedAndPending: statuses.includes('filed-and-pending') || statuses.length === 0,
        page: newPage,
        paused: statuses.includes('paused'),
        perPage: PAGE_SIZE,
        personId,
        preparingCase: statuses.includes('preparing-case') || statuses.length === 0,
        search: newSearch,
      });

      if (thisRequestId === mostRecentRequestRef.current) {
        if (response.data) {
          setNumPages(response.data.numPages);
          setUserWorkflows(response.data.items);
        }
        setIsFetching(false);
      }
    }
  }, [creatorId, getUserWorkflowsFn]);

  const handleNewPage = (newPage: number): void => {
    fetchWorkflows({
      page: newPage,
      search: searchTerm,
      statuses: selectedStatuses,
    });
  };

  useEffect(() => {
    const getCounts = async (): Promise<void> => {
      if (creatorId) {
        const getParams = {
          creatorId,
          page: 1,
          perPage: 1,
          personId,
          search: searchTerm,
        };
        const [
          completedResponse,
          archivedResponse,
          pausedResponse,
          dataCollectionResponse,
          filedAndPendingResponse,
          preparingCaseResponse,
        ] = await Promise.all([
          getUserWorkflowsFn({
            completed: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            archived: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            paused: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            dataCollection: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            filedAndPending: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            preparingCase: true,
            ...getParams,
          }),
        ]);
        setNumCompletedUserWorkflows(completedResponse?.data?.numItems ?? 0);
        setNumArchivedUserWorkflows(archivedResponse?.data?.numItems ?? 0);
        setNumPausedUserWorkflows(pausedResponse?.data?.numItems ?? 0);
        setNumDataCollectionUserWorkflows(dataCollectionResponse?.data?.numItems ?? 0);
        setNumFiledAndPendingUserWorkflows(filedAndPendingResponse?.data?.numItems ?? 0);
        setNumPreparingCaseUserWorkflows(preparingCaseResponse?.data?.numItems ?? 0);
      } else {
        setNumCompletedUserWorkflows(0);
        setNumArchivedUserWorkflows(0);
        setNumPausedUserWorkflows(0);
        setNumDataCollectionUserWorkflows(0);
        setNumFiledAndPendingUserWorkflows(0);
        setNumPreparingCaseUserWorkflows(0);
      }
    };
    getCounts();
  }, [getUserWorkflowsFn, searchTerm, creatorId, personId, selectedId]);

  useEffect(() => {
    fetchWorkflows({
      page: 1,
      search: searchTerm,
      statuses: selectedStatuses,
    });
  }, [fetchWorkflows, searchTerm, selectedStatuses]);

  useEffect(() => {
    const needProgressIds = userWorkflows
      .filter(userWorkflow => hasAssignedTasksByUserWorkflow[userWorkflow.id] === undefined)
      .map(userWorkflow => userWorkflow.id);

    Promise.all(
      needProgressIds.map(async userWorkflowId => {
        setHasAssignedTasksByUserWorkflow(prev => ({ ...prev, [userWorkflowId]: 'fetching' }));
        const response = await getUserWorkflowProgressFn({ userWorkflowId });
        if (response.data) {
          const responseData = response.data;
          setHasAssignedTasksByUserWorkflow(prev => ({ ...prev, [userWorkflowId]: responseData.hasAssignedTasks }));
        }
      }),
    );
    // intentionally not looking at hasAssignedTasksByUserWorkflow, only do this if the userWorkflows changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWorkflows, getUserWorkflowProgressFn]);

  const pagination = {
    numPages,
    onChangePage: handleNewPage,
    page: currentPage,
  };

  const filterItems = useMemo(() => {
    const statuses = [
      {
        isChecked: selectedStatuses.includes('data-collection'),
        key: 'data-collection' as Status,
        label: `Data Collection (${numDataCollectionUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('preparing-case'),
        key: 'preparing-case' as Status,
        label: `Processing (${numPreparingCaseUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('filed-and-pending'),
        key: 'filed-and-pending' as Status,
        label: `Filed and Pending (${numFiledAndPendingUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('completed'),
        key: 'completed' as Status,
        label: `Completed (${numCompletedUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('paused'),
        key: 'paused',
        label: `Paused (${numPausedUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('archived'),
        key: 'archived' as Status,
        label: `Archived (${numArchivedUserWorkflows})`,
      },
    ];
    return statuses;
  }, [
    selectedStatuses,
    numArchivedUserWorkflows,
    numCompletedUserWorkflows,
    numDataCollectionUserWorkflows,
    numFiledAndPendingUserWorkflows,
    numPausedUserWorkflows,
    numPreparingCaseUserWorkflows,
  ]);

  const filters : NonNullable<HookResponse['filters']> = {
    items: [{
      isMultiSelect: true,
      items: filterItems,
      label: 'Status',
      onChange: (newStatuses) => setSelectedStatuses(newStatuses as Status[]),
    }],
  };

  const userWorkflowsWithStatusSelected = userWorkflows.filter(userWorkflow => {
    if(selectedStatuses.includes(userWorkflow.status) || selectedStatuses.length === 0) {
      return userWorkflow;
    }
    return null;
  });

  const items = userWorkflowsWithStatusSelected.map(userWorkflow => userWorkflowToItem({
    creatorSlug,
    hasAssignedTasksByUserWorkflow,
    selectedStatus: userWorkflow.status,
    userWorkflow,
  }));

  return {
    filters,
    getDetailPagePath,
    ifNoItemsText: 'No activity',
    isFetchingList: isFetching,
    items,
    onSearch: setSearchTerm,
    pagination,
    selectedId,
  };
};

export default useActivity;
