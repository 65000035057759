import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import CustomLink from '@components/CustomLink';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useState } from 'react';
import UserImage from '@components/UserImage';
import Buttons from '@components/Buttons';
import TabIcon from '@components/DesignLibrary/Atoms/TabIcon';
import X from '@components/Icons/X';
import List from '@components/Icons/List';
import * as tsClient from '@tsClient';
import AssociatedUserSelect from '@components/AssociatedUserSelect';
import ManageTeamModal from '@lib/UserProfile/components/ManageTeamModal';
import useNoodleApi from '@hooks/useNoodleApi';
import { useUser } from '@hooks';
import CreateOrganizationModal from '@modals/CreateOrganizationModal';
import s from './UserProfileNavigation.module.scss';

type TAB =
  | 'activity'
  | 'bookings'
  | 'bookmarks'
  | 'chat'
  | 'community'
  | 'home'
  | 'library';

const UserProfileNavigation: FC<{
  avatar: {
    color: string | undefined;
    name: string | undefined;
    image: string | undefined;
  };
  hasLeftNav: boolean;
  creator: {
    color: string | undefined;
    name: string | undefined;
    image: string | undefined;
    slug: string;
    id: string | undefined;
  };
  currentTab: TAB;
  enabledTabs: Record<TAB, boolean> | null;
}> = ({ avatar, hasLeftNav, creator, currentTab, enabledTabs }) => {
  const router = useRouter();
  const [isOpen, setOpen] = useState(hasLeftNav);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [isCreateOrganizationModalOpen, setCreateOrganizationModalOpen] = useState(false);
  const [user] = useUser();

  const userId = user?.id;
  const creatorSlug = creator.slug;

  const {
    data: organization,
    getData: getOrganization,
    fetchingState: { isFetching: isFetchingOrganization },
  } = useNoodleApi(tsClient.organizations.getOrganization);
  const { data: customer, getData: getNoodleCustomer } = useNoodleApi(tsClient.getNoodleCustomer);

  const PAGES = useMemo(() => {
    if (!creatorSlug) {
      return [];
    }
    const allTabs: Array<{
      key: TAB;
      to: string;
    }> = [
      {
        key: 'home',
        to: getUrl(IDENTIFIERS.USER_PROFILE, { creatorSlug }),
      },
      {
        key: 'chat',
        to: getUrl(IDENTIFIERS.USER_PROFILE_CHAT, { creatorSlug }),
      },
      {
        key: 'library',
        to: getUrl(IDENTIFIERS.USER_PROFILE_LIBRARY, { creatorSlug }),
      },
      {
        key: 'bookmarks',
        to: getUrl(IDENTIFIERS.USER_PROFILE_BOOKMARKS, { creatorSlug }),
      },
      {
        key: 'community',
        to: getUrl(IDENTIFIERS.USER_PROFILE_COMMUNITY, { creatorSlug }),
      },
      {
        key: 'bookings',
        to: getUrl(IDENTIFIERS.USER_PROFILE_BOOKINGS, { creatorSlug }),
      },
      {
        key: 'activity',
        to: getUrl(IDENTIFIERS.USER_PROFILE_ACTIVITY, { creatorSlug }),
      },
    ];

    return allTabs.filter(({ key }) => (
      key === currentTab // always show the current tab
      || (enabledTabs && enabledTabs[key])
    ));
  }, [creatorSlug, enabledTabs, currentTab]);

  useEffect(() => {
    if (!hasLeftNav) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [router.pathname, hasLeftNav]);

  useEffect(() => {
    if (!hasLeftNav && isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [hasLeftNav, isOpen]);

  useEffect(() => {
    if (userId && creator.id) {
      getOrganization({ creatorId: creator.id });
    }
  }, [userId, creator.id, getOrganization]);

  useEffect(() => {
    if (userId && creator.id) {
      getNoodleCustomer({ creatorId: creator.id });
    }
  }, [userId, creator.id, getNoodleCustomer]);

  return (
    <nav
      className={s.nav}
      {...((hasLeftNav && {
        style: {
          height: 'min(100vh, 100dvh)',
        },
      })
        || (isOpen
          && !hasLeftNav && {
          style: {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
          },
        }))}
    >
      {!hasLeftNav && (
        <div className={s.header}>
          <Buttons
            isWrapper
            onClick={() => setOpen(!isOpen)}
            className={s.menu}
            {...(isOpen && {
              style: {
                backgroundColor: 'var(--color-primary)',
                color: 'var(--color-gray-0)',
              },
            })}
          >
            {isOpen ? <X size={20} /> : <List size={20} />}
          </Buttons>
          <div>
            <UserImage size={24} name={avatar.name || null} url={avatar.image} color={avatar.color || null} />
            {avatar.name}
          </div>
          <div />
        </div>
      )}
      {isOpen && (
        <div className={!hasLeftNav ? s.firstLevelMobile : s.firstLevel}>
          {hasLeftNav && (
            <CustomLink
              to={getUrl(IDENTIFIERS.USER_PROFILE, {
                creatorSlug: creator.slug,
              })}
              className={s.logo}
            >
              <UserImage size={32} name={creator.name || null} url={creator.image} color={creator.color || null} />
            </CustomLink>
          )}
          <div className={!hasLeftNav ? s.tabsMobile : s.tabs}>
            {PAGES.map(page => (
              <TabIcon
                showLabel={!hasLeftNav}
                key={page.key}
                icon={page.key}
                href={page.to}
                selected={currentTab === page.key}
              />
            ))}
          </div>
          <div className={!hasLeftNav ? s.bottomMobile : s.bottom}>
            {organization && (
              <>
                <TabIcon
                  showLabel={!hasLeftNav}
                  icon="organization"
                  onClick={() => setTeamModalOpen(true)}
                  selected={false}
                  label={(organization.name && `Manage ${organization.name}`) || 'Manage Organization'}
                />
                {teamModalOpen && <ManageTeamModal organizationId={organization.id} onClose={() => setTeamModalOpen(false)} />}
              </>
            )}
            {!organization && !isFetchingOrganization && customer?.canCreateOrganization && (
              <>
                <TabIcon
                  showLabel={!hasLeftNav}
                  icon="add"
                  onClick={() => setCreateOrganizationModalOpen(true)}
                  selected={false}
                  label="Create Organization"
                />
                {isCreateOrganizationModalOpen && creator.id && (
                  <CreateOrganizationModal
                    creatorId={creator.id}
                    beforeClose={async () => {
                      await getOrganization({ creatorId: creator.id || '' });
                    }}
                    onClose={() => setCreateOrganizationModalOpen(false)}
                  />
                )}
              </>
            )}
            <AssociatedUserSelect isIcon={hasLeftNav} redirectTo={getUrl(IDENTIFIERS.USER_PROFILE, { creatorSlug: creator.slug })} />
            {!hasLeftNav
              ? (
                <TabIcon
                  selected={false}
                  showLabel
                  icon="account"
                  href={getUrl(IDENTIFIERS.USER_PROFILE_EDIT, {
                    creatorSlug: creator.slug,
                  })}
                />
              )
              : (
                <CustomLink
                  to={getUrl(IDENTIFIERS.USER_PROFILE_EDIT, {
                    creatorSlug: creator.slug,
                  })}
                >
                  <UserImage url={avatar.image} name={avatar.name} color={avatar.color} size={32} /> {!hasLeftNav && 'Your Account'}
                </CustomLink>
              )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default UserProfileNavigation;
