import isInIframe from '@helpers/isInIframe';
import { useFeatureFlag } from '@providers/FeatureFlags';
import UserProfileLayout from './UserProfileLayout';
import ActivitySubLayout from './SubLayouts/Activity';
import UserProfileContext from './UserProfileContext';

const UserProfileLayoutMaybe: typeof UserProfileLayout = ({ children, ...props }) => {
  const userProfileV3Enabled = useFeatureFlag('user-profile-v3');
  if (!userProfileV3Enabled || isInIframe()) {
    return <>{children}</>;
  }

  return <UserProfileLayout {...props}>{children}</UserProfileLayout>;
};

export default UserProfileLayoutMaybe;

export {
  ActivitySubLayout,
  UserProfileContext,
};
