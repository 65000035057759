import { useEffect, useMemo, useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useParams } from 'next/navigation';
import LoggedInUserGate from '@components/Auth/LoggedInUserGate';
import scrollParentToTop from '@helpers/scrollParentToTop';
import { useIsMobile, useUser } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import { useCreatorUI } from '@providers/CreatorUI';
import { mixpanelTrack } from '@providers/Mixpanel';
import { Layout } from '@typings/NextJS';
import * as tsClient from '@tsClient';
import Panes from '@panes';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import UserProfileNavigation from './components/UserProfileNavigation';
import UserProfileContext from './UserProfileContext';
import s from './UserProfileLayout.module.scss';

type Props = {
  tab: Parameters<typeof UserProfileNavigation>[0]['currentTab']
};

const UserProfileLayout: Layout<Props> = ({ children, tab: currentTab }) => {
  const creator = useCreatorUI();
  const [user] = useUser();
  const router = useRouter();
  // can't useQueryState because that updates before currentTab
  // This causes the !home enabled redirect to trigger after the ?tab= is removed but before the tab has changed
  // Make sure any changes to query handling is also handled in the V1 UserProfile
  const queryTab = deserializeQueryStringItem(router.query.tab);
  const queryUserWorkflowId = deserializeQueryStringItem(router.query.userWorkflowId);
  const threadId = deserializeQueryStringItem(router.query.threadId);
  const commentId = deserializeQueryStringItem(router.query.commentId);
  const isMobile = useIsMobile();
  const routerParams = useParams<{creatorSlug: string;}>();
  const creatorSlug = routerParams?.creatorSlug;
  const creatorId = creator?.id ?? null;
  const [browserTabTitle, setBrowserTabTitle] = useState<string | null>(null);

  const { data: userProfile, getData: getUserProfileFn } = useNoodleApi(tsClient.my.getProfile);
  const { data: creatorData, getData: getCreatorFn } = useNoodleApi(tsClient.getCreator);
  const { data: enabledTabs, getData: getUserProfileTabs } = useNoodleApi(tsClient.my.getUserProfileTabs);
  const hasLeftNav = !isMobile;

  useEffect(() => {
    if (user?.id && creatorSlug) {
      getUserProfileFn();
      getCreatorFn({ creatorSlug });
      mixpanelTrack('Viewed User Profile', {
        creator: creatorSlug,
      });
      scrollParentToTop();
    }
  }, [user?.id, creatorSlug, getUserProfileFn, getCreatorFn]);

  useEffect(() => {
    if (user?.id && creatorId) {
      getUserProfileTabs({ creatorId });
    }
  }, [user?.id, creatorId, getUserProfileTabs]);

  useEffect(() => {
    if (creatorSlug && currentTab === 'home') {
      // If on the home page, if the tab is specified in the qs, redirect to that page
      if (queryTab) {
        if (queryTab === 'activity') {
          if (queryUserWorkflowId) {
            router.replace(getUrl(IDENTIFIERS.USER_PROFILE_ACTIVITY_DETAILS, {
              commentId,
              creatorSlug,
              threadId,
              userWorkflowId: queryUserWorkflowId,
            }));
          } else {
            router.replace(getUrl(IDENTIFIERS.USER_PROFILE_ACTIVITY, { creatorSlug }));
          }
        } else if (queryTab === 'bookings') {
          router.replace(getUrl(IDENTIFIERS.USER_PROFILE_BOOKINGS, { creatorSlug }));
        } else if (queryTab === 'bookmarks') {
          router.replace(getUrl(IDENTIFIERS.USER_PROFILE_BOOKMARKS, { creatorSlug }));
        } else if (queryTab === 'chat') {
          router.replace(getUrl(IDENTIFIERS.USER_PROFILE_CHAT, { creatorSlug }));
        } else if (queryTab === 'community') {
          router.replace(getUrl(IDENTIFIERS.USER_PROFILE_COMMUNITY, { creatorSlug }));
        } else if (queryTab === 'library') {
          router.replace(getUrl(IDENTIFIERS.USER_PROFILE_LIBRARY, { creatorSlug }));
        }
      } else if (enabledTabs) {
        // If we know which tabs are enabled, and home is not one of them
        // redirect first to activity, then chat.
        if (!enabledTabs.home) {
          if (enabledTabs.activity) {
            router.replace(getUrl(IDENTIFIERS.USER_PROFILE_ACTIVITY, { creatorSlug }));
          } else if (enabledTabs.chat) {
            router.replace(getUrl(IDENTIFIERS.USER_PROFILE_CHAT, { creatorSlug }));
          }
        }
      }
    }
  }, [creatorSlug, currentTab, enabledTabs, queryTab, queryUserWorkflowId]);

  const contextValue = useMemo(
    () => ({
      browserTabTitle,
      creatorId,
      creatorSlug,
      isInUserProfile: true,
      setBrowserTabTitle,
    }),
    [creatorId, creatorSlug, setBrowserTabTitle, browserTabTitle],
  );

  const currentPath = useRef('');
  currentPath.current = router.asPath;

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      const newPathName = url.replace(/\?.*/, '');
      const oldPathName = currentPath.current.replace(/\?.*/, '');
      if (newPathName !== oldPathName) {
        setBrowserTabTitle(null);
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [router.events]);

  return (
    <LoggedInUserGate creator={creator} isAnonymousAllowed={false}>
      <UserProfileContext.Provider value={contextValue}>
        <Head>
          <title>{browserTabTitle ? `${browserTabTitle} · Your Profile` :'Your Profile'}</title>
        </Head>
        <div className={s.layout}>
          {creatorSlug && (
            <UserProfileNavigation
              avatar={{
                color: userProfile?.primaryColour?.hex,
                image: userProfile?.image?.url,
                name: userProfile?.name ?? undefined,
              }}
              creator={{
                color: creatorData?.name ? 'var(--color-primary)' : 'var(--color-gray-25)',
                id: creatorData?.id,
                image: creatorData?.person?.image?.url,
                name: creatorData?.name ?? undefined,
                slug: creatorSlug,
              }}
              hasLeftNav={hasLeftNav}
              currentTab={currentTab}
              enabledTabs={enabledTabs}
            />
          )}
          <Panes hasLeftNav={hasLeftNav} />
          {children}
        </div>
      </UserProfileContext.Provider>
    </LoggedInUserGate>
  );
};

export default UserProfileLayout;
