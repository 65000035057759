import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import type { ListViewRegularItem } from '@layouts/ListAndDetailsSubLayout';

export type HookResponse = Pick<Parameters<typeof ListAndDetailsSubLayout>[0],
  | 'items'
  | 'filters'
  | 'pagination'
  | 'selectedId'
  | 'isFetchingList'
  | 'ifNoItemsText'
  | 'getDetailPagePath'
  | 'onSearch'
>;

export const PAGE_SIZE = 25;

export type { ListViewRegularItem };
