import { useContext, useMemo } from 'react';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import { useUser } from '@hooks';
import { useCreatorUI } from '@providers/CreatorUI';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import useActivity from './useActivity';

const Activity: React.FC = ({ children }) => {
  const isOneColumn = useIsMobile(SIZES.xl);
  const { creatorSlug } = useContext(UserProfileContext);
  const [user] = useUser();
  const creator = useCreatorUI();
  const activityData = useActivity({
    creatorId: creator?.id,
    creatorSlug,
    personId: user?.id,
  });

  const header = useMemo(() => ({
    title: "Activity",
  }), []);

  return (
    <ListAndDetailsSubLayout
      header={header}
      isOneColumn={isOneColumn}
      resourceType={'activity'}
      {...activityData}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Activity;
