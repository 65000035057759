import { useState } from 'react';
import Modal from '@components/Modal';
import EmailInput from '@components/FormFields/EmailInput';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import s from './AssociatedUserSelect.module.scss';

type Props = {
  onClose: () => void;
  onSubmit: (args: { email: string; name: string; }) => Promise<void>;
};

const AddNewUserModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    setHasAttemptedSubmit(true);
    if (emailError || nameError) {
      return;
    }
    setIsSaving(true);
    await onSubmit({ email, name });
    setIsSaving(false);
  };

  return (
    <Modal
      hideBottomInset

      title={'Create a new user'}
      onClose={onClose}

    >
      <div className={s.addAssociatedUser}>
        <TextInput
          id={'name'}
          value={name}
          onChange={setName}
          label={'Name'}
          placeholder={`Enter name`}
          autoComplete={'name'}
          onError={setNameError}
          required
          isTouched={hasAttemptedSubmit}
        />
        <Spacer size={12}/>
        <EmailInput
          id="email"
          error={emailError}
          value={email}
          onChange={setEmail}
          onError={setEmailError}
          label="Email"
          required
          placeholder={`Enter email`}
          isTouched={hasAttemptedSubmit}
        />
        <Spacer size={12}/>
        <Buttons isSecondary isFullWidth onClick={handleSubmit} isFetching={isSaving}>
          Create & Use
        </Buttons>
      </div>
    </Modal>
  );
};

export default AddNewUserModal;
