import { ReactElement, useEffect, useState } from 'react';

import Modal from '@components/Modal';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import InputField from '@components/FormFields/InputField';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import { mixpanelTrack } from '@providers/Mixpanel';
import s from './CreateOrganizationModal.module.scss';

type Props = {
  onClose: () => void;
  creatorId: string;
  beforeClose: () => Promise<void>;
};

const CreateOrganizationModal = ({ onClose, creatorId, beforeClose }: Props): ReactElement => {
  const [name, setName] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { getData: createOrganization } = useNoodleApi(tsClient.organizations.createOrganization);

  const handleSave = async (): Promise<void> => {
    setIsFetching(true);
    await createOrganization({ creatorId, name });
    await beforeClose();
    onClose();
  };

  useEffect(() => {
    mixpanelTrack('Edit mode - edit product', {
      field: 'title',
    });
  }, []);

  return (
    <Modal

      title='Create Organization'
      onClose={onClose}

    >
      <div className={s.container}>
        <InputField id='name' name='name' label='Name' placeholder="Name" values={{ name }} onChange={setName} />
        <Spacer size='8px' />
        <Buttons
          isFetching={isFetching}
          isFullWidth
          isSecondary
          disabled={!name}
          onClick={handleSave}
        >
          Save
        </Buttons>
      </div>
    </Modal>
  );
};

export default CreateOrganizationModal;
